<template>
  <main class="w-full h-full md:flex md:justify-center md:items-center">
    <div class="hidden md:block background fixed z-10"></div>
    <div
      class="w-auto lg:w-full relative z-20 bg-white m-auto flex flex-col-reverse lg:flex-row flex-wrap lg:flex-nowrap justify-between md:max-w-[500px] lg:max-w-6xl md:mx-8 xl:mx-0 xl:max-w-7xl shadow-2xl lg:rounded-[38px]"
    >
      <div class="w-full lg:w-[530px] p-4 text-[16px] text-center">
        <img
          src="@/assets/Jacquet_logo_carre_sans_ombre-2.png"
          class="w-[50%] sm:w-[200px] lg:w-[50%] m-auto sm:mb-12 lg:mb-0 mt-[7%]"
        />

        <div
          class="text-[5vw] leading-[6vw] sm:text-lg lg:text-[1.5vw] xl:text-[1.4em] sm:leading-6 lg:leading-[1.2em] font-semibold mt-3 lg:mt-[1em] text-gray-700 bg-gray-100 rounded-[.5em] p-[2vw] sm:p-[10px] lg:p-[1.5vw] xl:p-[1em] mx-4 lg:mx-[1vw] xl:mx-[.8em]"
        >
          <p v-if="operation === '672341d5304a250e98c892fd'">
            Du 15 Novembre 2024 <br />au 15 Fevrier 2025
          </p>
          <p v-if="operation === '67234599304a250e98c9358d'">
            Du 18 Novembre 2024 <br />au 13 Janvier 2025
          </p>
          <p v-if="operation === '672342b8304a250e98c8c643'">
            Du 18 Novembre 2024 <br />au 13 Janvier 2025
          </p>
        </div>
        <p>
          <button
            @click="goToOperation"
            class="bg-[#0054B9] text-white text-[6vw] sm:text-xl lg:text-[2vw] xl:text-[1.7em] px-4 lg:px-[2vw] xl:px-[1em] py-[1vw] sm:py-1 lg:py-[.4vw] xl:py-[.2em] rounded-[.2em] my-[12vw] sm:my-10 lg:my-[4vw] xl:my-[2em]"
          >
            JE PARTICIPE
          </button>
        </p>
      </div>
      <div><img src="@/assets/ban.png" class="w-full lg:w-auto" /></div>
    </div>
  </main>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router/composables";
const router = useRouter();

let operation = ref("672341d5304a250e98c892fd");

const goToOperation = () => {
  router.push({ path: `/operation/${operation.value}` });
  console.log(operation.value);
};
</script>
