<template>
  <main class="absolute w-full h-full inset-0">
    <iframe
      v-if="id"
      height="100%"
      class="w-full h-full border-none"
      :src="`https://test.promo.dev/${id}#form`"
    ></iframe>
  </main>
</template>
<script>
export default {
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
  },
};
</script>
